<template>
  <div class="personnel">
    <van-nav-bar
      title="所属组织"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-search
      v-model="searchValue"
      placeholder="输入名称查找"
      @input="searchlist"
    />
    <div class="roblist" v-for="(item, index) in list" :key="index">
      <van-cell class="title">{{ item.name }}/{{ item.phone }}</van-cell>
      <van-cell
        title="所属组织"
        :value="item.organization ? item.organization.name : ''"
        size="large"
      />
      <van-cell title="岗位" :value="item.position" size="large" />
    </div>
  </div>
</template>

<script>
import { Organizationlist } from '@/api/index'

export default {
  name: "personnel",
  data() {
    return {
      searchValue: '',
      list: []
    };
  },
  created() {
    Organizationlist({ limit: 1000, offset: 0, personal: true }).then((response) => {
      this.list = response.data.results
      console.log(response)
    })
  },
  methods: {
    searchlist() {
      Organizationlist({ limit: 1000, offset: 0, personal: true, q: this.searchValue }).then((response) => {
        this.list = response.data.results
        console.log("response", response)
      })
    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.personnel {
  margin-bottom: 55px;
  background: #f3f3f3;
  .van-search {
    background: #f3f3f3;
  }

  .van-search__content {
    background: #fff;
  }

  .roblist {
    border-bottom: 1px solid #ccc;
    background: #fff;
    padding-top: 2px;
    margin-bottom: 10px;

    p {
      margin: 0.6rem 0;
      font-size: 13px;
    }

    .title {
      font-size: 14px;
      //padding-left: 4%;
      font-weight: bold;

      span {
        float: right;
        display: flex;
        align-items: center;

        b {
          display: block;
          width: 14px;
          height: 14px;
          background: red;
          border-radius: 50%;
          margin-right: 2px;
        }

        .now {
          background-color: #04f9ce;
        }
      }
    }

    .subtitle {
      font-size: 13px;
      color: #999;
    }

    .warning {
      font-size: 12px;
      color: red;
    }
  }

  //.roblist:nth-child(2) {
  //  border-top: 1px solid #ccc;
  //}
}
</style>
